import moment from 'moment';

export const repeatAction = (action: any) => {
	let duration: any = action.repeat_duration;
	let unit = action.repeat_unit;

	return {
		type: '',
		color_status: 0,
		date: moment(action.date, 'MM/DD/YYYY')
			.add(duration, unit)
			.format('MM/DD/YYYY'),
		full_date: moment(action.full_date, 'MM/DD/YYYY HH:mm')
			.add(duration, unit)
			.format('MM/DD/YYYY HH:mm'),
		utc_date: moment(action.utc_date, 'MM/DD/YYYY')
			.add(duration, unit)
			.format('MM/DD/YYYY'),
		utc_full_date: moment(action.utc_full_date, 'MM/DD/YYYY HH:mm')
			.add(duration, unit)
			.format('MM/DD/YYYY HH:mm'),
	};
};

export const getBackgroundColor = (status: string) => {
	switch (status) {
		case 'No Action Required':
			return {
				backgroundColor: '#188038',
			};
		case 'Deadline Imminent':
			return {
				backgroundColor: '#F4B400',
			};
		case 'Deadline Passed':
			return {
				backgroundColor: '#B92222',
			};
		case 'Submission Rejected':
			return {
				backgroundColor: '#ED7843',
			};
		case 'Approval Required':
			return {
				backgroundColor: '#1F59DF',
			};
		case 'Delinquent':
			return {
				backgroundColor: '#000000',
			};

		default:
			return {
				backgroundColor: '#DEE2E9',
			};
	}
};
